import React, { useState, Suspense } from 'react';
import styles from './Dashboard.module.css';

// Lazy load components
const UserTable = React.lazy(() => import('./UserTable'));
const ServiceTable = React.lazy(() => import('./ServiceTable'));
const ProfileTable = React.lazy(() => import('./ProfileTable'));
const DoctorTable = React.lazy(() => import('./DoctorTable'));
const VideoTable = React.lazy(() => import('./VideoTable'));
const BeforeAndAfterTable = React.lazy(() => import('./BeforeAndAfterTable'));

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('users'); // Default tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.container} dir='ltr'>
      <div className={styles.title}>
        DashBoard For Admins
      </div>

      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'users' ? styles.active : ''}`} 
          onClick={() => handleTabClick('users')}
        >
          Patient Table
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'services' ? styles.active : ''}`} 
          onClick={() => handleTabClick('services')}
        >
          Offers Table
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'profile' ? styles.active : ''}`} 
          onClick={() => handleTabClick('profile')}
        >
          Profile Table
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'doctor' ? styles.active : ''}`} 
          onClick={() => handleTabClick('doctor')}
        >
          Doctors Table
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'before' ? styles.active : ''}`} 
          onClick={() => handleTabClick('before')}
        >
          Before And After Table
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'video' ? styles.active : ''}`} 
          onClick={() => handleTabClick('video')}
        >
          Videos Table
        </button>
      </div>

      <div className={styles.tableContainer}>
        <Suspense fallback={<div>Loading...</div>}>
          {activeTab === 'users' && <UserTable />}
          {activeTab === 'services' && <ServiceTable />}
          {activeTab === 'profile' && <ProfileTable />}
          {activeTab === 'doctor' && <DoctorTable />}
          {activeTab === 'before' && <BeforeAndAfterTable />}
          {activeTab === 'video' && <VideoTable />}
        </Suspense>
      </div>
    </div>
  );
};

export default Dashboard;
