import React from 'react';
import ReactCompareImage from 'react-compare-image';
import styles from './BeforeAndAfterImages.module.css';

const AllImagesPage = () => {
    const images = [
        { left: '/beforeAndafter/05.jpg', right: '/beforeAndafter/055.jpg' },
        { left: "/beforeAndafter/02.jpg", right: "/beforeAndafter/022.jpg" },
        { left: "/beforeAndafter/03.jpg", right: "/beforeAndafter/033.jpg" },
        { left: "/beforeAndafter/04.jpg", right: "/beforeAndafter/044.jpg" },
        { left: '/beforeAndafter/06.jpg', right: '/beforeAndafter/066.jpg' },
        { left: '/beforeAndafter/07.jpg', right: '/beforeAndafter/077.jpg' }
    ];

    return (
        <div className={styles.homeContainer} style={{marginTop: '90px'}}>
            {images.map((image, index) => (
                <div className={styles.container} key={index}>
                    <ReactCompareImage leftImage={image.left} rightImage={image.right} />
                </div>
            ))}
        </div>
    );
};

export default AllImagesPage;
