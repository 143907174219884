import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import styles from './ContactIcons.module.css'; // Import your CSS module

const ContactIcons = () => {
    const navigate = useNavigate();
    const [contactInfo, setContactInfo] = useState({ phone: '', whatsappNum: '' });
    const [isVisible, setIsVisible] = useState(true); // State to manage visibility

    const url = process.env.REACT_APP_BASE_URL;


    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const response = await fetch(`${url}/api/v1/profiles/getAllProfiles`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data && data.length > 0) {
                    setContactInfo({
                        phone: data[0].phone,
                        whatsappNum: data[0].whatsappNum
                    });
                }
            } catch (error) {
                console.error('Error fetching contact info:', error);
            }
        };

        fetchContactInfo();

        // Function to handle scroll event
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if we're within 300 pixels of the bottom
            if (documentHeight - scrollY - windowHeight < 650) {
                setIsVisible(false); // Hide the icons
            } else {
                setIsVisible(true); // Show the icons
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Navigation handler
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <>
            {isVisible && ( // Conditionally render icons based on visibility
                <div className={styles.contactIcons}>
                    <div onClick={() => handleNavigation('/#User')}  role="button"  aria-label="Navigate to User section">
                        <FontAwesomeIcon icon={faAddressBook} size="2x" className={styles.icon} />
                    </div>

                    <div className={styles.icon}  role="button"  aria-label={`Chat with us on WhatsApp at ${contactInfo.whatsappNum}`}
                        onClick={() => window.open(`https://wa.me/2${contactInfo.whatsappNum}`, '_blank')}>
                        <FontAwesomeIcon icon={faWhatsapp} size="2x" className={styles.whatsappIcon} />
                    </div>

                    <div className={styles.icon}  role="button"  aria-label={`Call us at ${contactInfo.phone}`}
                        onClick={() => window.open(`tel:${contactInfo.phone}`, '_self')}>
                        <FontAwesomeIcon icon={faPhoneAlt} size="2x" className={styles.phoneIcon} />
                    </div>
                </div>
            )}
        </>
    );
};

export default ContactIcons;
