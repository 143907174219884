import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styles from './forgetPassword.module.css'
import ConfirmOTP from '../Auth/ConfirmOTP'

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [showConfirmOtp, setShowConfirmOtp] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); // State for error message
    const [email, setEmail] = useState(''); // State to store the email

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    const url = process.env.REACT_APP_BASE_URL;

    const validationSchema = Yup.object({
        email: Yup.string()
            .required('Email is required')
            .matches(emailRegex, 'Email invalid')
            .email('Email invalid'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch(`${url}/api/forgot-password/send-otp?email=${encodeURIComponent(values.email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.text(); // Get the error message from response
                setErrorMessage(errorData.message || "An error occurred");  // Set the error message to state
                throw new Error(errorData); // Throw an error with the response message
            }

            const responseData = await response.text(); // Optional: Get the response message
            // console.log(responseData); // Log success message

            setEmail(values.email);
            setShowConfirmOtp(true); // Show the OTP confirmation component
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error.message); // Set the error message to state
            // Optionally, you can set an error state to display a message to the user
        } finally {
            setSubmitting(false);
        }
    };

    const handleReturnButtonClick = () => {
        navigate("/login");
    };

    if (showConfirmOtp) {
        return  <ConfirmOTP  email={email}/>; 
    }

    return (
        <div className={styles.conatiner}>
            <div>
                <Formik
                    initialValues={{ email: "" }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting }) => (
                        <Form className={styles.secondContainer}>
                            <div className={styles.headingContainer}>
                                <h2 className={styles.heading}>Forget Password</h2>
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="email" className={styles.label}>
                                    Enter Your email
                                </label>
                                <Field
                                    type="email"
                                    id="email"
                                    name="email"
                                    className={styles.inputField}
                                />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className={`${styles.errorMessage} fs-12 mt-0.5`}
                                />
                            </div>

                            {/* Displaying the error message */}
                            {errorMessage && (
                                <div className={`${styles.errorMessage} fs-12 mt-0.5`}>
                                    {errorMessage}
                                </div>
                            )}

                            <div className="d-flex justify-content-between mt-4">
                                <button
                                    type="submit"
                                    className={`${styles.submitButton}`}
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>

                                <button
                                    type="button"
                                    onClick={handleReturnButtonClick}
                                    className={`${styles.returnButton}`}
                                >
                                    return
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default ForgetPassword