import React, { useState } from 'react';
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CountdownTimer from '../ResendOtpCounter/CountDownTimer';
import ConfirmPassword from '../Auth/ConfirmPassword';
import styles from '../ForgetPassword/forgetPassword.module.css';
import { useNavigate } from "react-router-dom";

const ConfirmOTP = ({ email }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [keyReset, setKeyReset] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');

  const validationSchema = Yup.object({
    otp: Yup.array()
      .of(Yup.string().required("OTP is required"))
      .length(6, "OTP must be exactly 6 digits"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const otpString = values.otp.join(''); // Combine the OTP digits into a string

    try {
      const response = await fetch(`http://localhost:8080/api/forgot-password/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp: otpString }), // Send email and OTP
      });

      if (!response.ok) {
        const errorData = await response.text(); // Get the error message from response
        throw new Error(errorData); // Throw an error with the response message
      }

      // If OTP verification is successful
      setShowConfirmPassword(true);
    } catch (error) {
      setError(error.message); // Set the error message to show to the user
    } finally {
      setSubmitting(false);
    }
  };

  const handleReturnButtonClick = () => {
    navigate("/login");
  };

  const handleResendButtonClick = () => {
    if (!isTimerActive) {
      setKeyReset((prevKey) => prevKey + 1);
    }
  };

  const handleTimerComplete = () => {
    setIsTimerActive(false);
  };

  if (showConfirmPassword) {
    return <ConfirmPassword email={email} />;
  }

  return (
    <div className={styles.conatiner}>
      <div>
        <Formik
          initialValues={{ otp }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting, touched, errors, setFieldValue, values }) => (
            <Form className={`${styles.secondContainer} ${styles.sentOtp} grid`}>
              <div className={styles.headingContainer}>
                <h2 className={styles.heading}>OTP Page</h2>
              </div>
              <div className="d-flex justify-content-center mb-4">
                <label className={`${styles.label} fs-6`}>Write OTP</label>
              </div>
              <FieldArray name="otp">
                {() => (
                  <div className={`${styles.numbersOtp} d-flex justify-content-evenly mb-4`}>
                    {values.otp.map((_, index) => (
                      <div key={index}>
                        <Field
                          className={`${styles.inputOtp} w-15`}
                          name={`otp.${index}`}
                          type="text"
                          maxLength="1"
                          onChange={(e) => {
                            const value = e.target.value;
                            const newOtp = [...otp];
                            if (/^\d$/.test(value)) {
                              newOtp[index] = value;
                              setOtp(newOtp);
                              setFieldValue(`otp.${index}`, value);
                              if (value && index < otp.length - 1) {
                                document.getElementById(`otp-${index + 1}`).focus();
                              }
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace" && !otp[index] && index > 0) {
                              document.getElementById(`otp-${index - 1}`).focus();
                            }
                            if (e.key === "Backspace") {
                              const newOtp = [...otp];
                              newOtp[index] = "";
                              setOtp(newOtp);
                              setFieldValue(`otp.${index}`, "");
                            }
                          }}
                          onFocus={(e) => e.target.select()}
                          id={`otp-${index}`}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>

              {touched.otp && errors.otp && (
                <div className={styles.otpErrorMessage}>{errors.otp.join(", ")}</div>
              )}
              {error && <div className={styles.otpErrorMessage}>{error}</div>}

              {values.otp.join("").length !== 6 && (
                <div className={styles.otpErrorMessage}>
                  <div className="d-flex justify-content-center">
                    <span><AccessTimeIcon /></span>
                    <span className={styles.counter}>
                      <CountdownTimer keyReset={keyReset} onTimerComplete={handleTimerComplete} />
                    </span>
                    <span>seconds</span>
                  </div>
                </div>
              )}

              <div className={styles.otpBtns}>
                <button
                  className={`${styles.submitOtpButton} fw-bold`}
                  type="submit"
                  disabled={isSubmitting || values.otp.join("").length !== 6}
                >
                  Submit
                </button>

                <button
                  className={`${styles.resentButton} fw-bold ${isTimerActive ? styles.disabledButton : ""}`}
                  type="button"
                  onClick={handleResendButtonClick}
                  disabled={isTimerActive}
                >
                  Resend
                </button>

                <button
                  className={`${styles.closeButton} fw-bold`}
                  type="button"
                  onClick={handleReturnButtonClick}
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ConfirmOTP;
