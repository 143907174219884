import React, { useState } from 'react';
import styles from '../ForgetPassword/forgetPassword.module.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const ConfirmPassword = ({ email }) => {
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const validationSchema = Yup.object({
        passwordOne: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        passwordTwo: Yup.string()
            .oneOf([Yup.ref('passwordOne'), null], 'Passwords do not match')
            .required('Confirm Password is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch("http://localhost:8080/api/reset-password/restPassword", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: values.passwordOne,
                    confirmedPassword: values.passwordTwo
                }),
            });

            if (!response.ok) {
                const errorData = await response.text(); // Get the error message from response
                throw new Error(errorData); // Throw an error with the response message
            }

            // If the reset password is successful
            navigate("/login"); // Redirect to login page
        } catch (error) {
            setError(error.message); // Set the error message to show to the user
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className={styles.conatiner}>
            <div>
                <Formik
                    initialValues={{ passwordOne: "", passwordTwo: "" }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting }) => (
                        <Form className={styles.secondContainer}>
                            <div className={styles.headingContainer}>
                                <h2 className={styles.heading}>Confirm Password</h2>
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="passwordOne" className={styles.label}>
                                    Password
                                </label>
                                <Field
                                    type="password"
                                    id="passwordOne"
                                    name="passwordOne"
                                    className={styles.inputField}
                                />
                                <ErrorMessage
                                    name="passwordOne"
                                    component="div"
                                    className={styles.errorMessage}
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="passwordTwo" className={styles.label}>
                                    Reset Password
                                </label>
                                <Field
                                    type="password"
                                    id="passwordTwo"
                                    name="passwordTwo"
                                    className={styles.inputField}
                                />
                                <ErrorMessage
                                    name="passwordTwo"
                                    component="div"
                                    className={styles.errorMessage}
                                />
                            </div>

                            {error && <div className={styles.errorMessage}>{error}</div>}

                            <div className={`${styles.passwordsBtns}`}>
                                <button
                                    className={`${styles.submitPasswordButton} fw-bold`}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                                <button
                                    className={`${styles.closePasswordButton} fw-bold`}
                                    type="button"
                                    onClick={() => navigate("/login")}
                                >
                                    Close
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default ConfirmPassword;
