import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';

function Header() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isAuthenticated = !!localStorage.getItem('authToken');
    const [isOpen, setIsOpen] = useState(false);
    const url = process.env.REACT_APP_BASE_URL;

    const toggleLanguage = () => {
        const newLang = i18n.language === 'ar' ? 'en' : 'ar';
        i18n.changeLanguage(newLang);
        document.body.classList.toggle('rtl', newLang === 'ar');
        document.body.classList.toggle('ltr', newLang === 'en');
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(`${url}/logout/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            });

            if (!response.ok) {
                throw new Error('Logout failed!');
            }

            localStorage.removeItem('authToken');
            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleNavigation = (path) => {
        console.log(path);
        navigate(path);
        setIsOpen(false); // Close menu on navigation
    };


    return (
        <header className={`${styles.navBar}`}>
            <div className={styles.container}>
                <div className={styles.brand} onClick={() => handleNavigation('/#Home')}>
                    <img loading="eager" src={'/logo.jpg'} width="60" height="60" alt='دكتور اسنان'  style={{ cursor: 'pointer' }} />
                </div>
                <button className={styles.toggleBtn} onClick={() => setIsOpen(!isOpen)}>
                    <h2>☰</h2>
                </button>
                <nav className={`${styles.offcanvas} ${isOpen ? styles.show : ''}`}>
                    <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>×</button>
                    <ul className={styles.navLinks}>
                        <li onClick={() => handleNavigation('/#Home')}>{t('navBar.Home')}</li>
                        <li onClick={() => handleNavigation(`/#OurService`)}>{t('navBar.OurService')}</li>
                        <li onClick={() => handleNavigation('/medicalTourism')}>{t('navBar.MedicalTourism')}</li>
                        <li onClick={() => handleNavigation('/about')}>{t('navBar.About')}</li>
                        <li onClick={() => handleNavigation('/#ContactUs')}>{t('navBar.ContactUs')}</li>

                        {isAuthenticated && (
                            <>
                                <li onClick={() => handleNavigation('/dashboard')}>{t('navBar.Dashboard')}</li>
                                <li onClick={handleLogout}>{t('navBar.Logout')}</li>
                            </>
                        )}
                        <li style={{backgroundColor: '#000'}} className={styles.btn} onClick={toggleLanguage}>
                            {i18n.language === 'ar' ? 'EN' : 'AR'}
                        </li>
                    </ul>
                </nav>
                <ul className={`${styles.navLinksDesktop} ${isOpen ? styles.show : ''}`}>
                    <li onClick={() => handleNavigation('/#Home')}>{t('navBar.Home')}</li>
                    <li onClick={() => handleNavigation(`/#OurService`)}>{t('navBar.OurService')}</li>
                    <li onClick={() => handleNavigation('/medicalTourism')}>{t('navBar.MedicalTourism')}</li>
                    <li onClick={() => handleNavigation('/about')}>{t('navBar.About')}</li>
                    <li onClick={() => handleNavigation('/#ContactUs')}>{t('navBar.ContactUs')}</li>

                    {isAuthenticated && (
                        <>
                            <li onClick={() => handleNavigation('/dashboard')}>{t('navBar.Dashboard')}</li>
                            <li onClick={handleLogout}>{t('navBar.Logout')}</li>
                        </>
                    )}
                    <li style={{backgroundColor: '#000'}} className={styles.btn} onClick={toggleLanguage}>
                        {i18n.language === 'ar' ? 'EN' : 'AR'}
                    </li>
                </ul>
            </div>
        </header>
    );
}

export default Header;
