import React, { useEffect, useState } from 'react';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp, faInstagram, faSnapchatGhost, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons'; // Import YouTube icon
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ServiceLinks from './ServiceLinks';

const Footer = () => {
    const [profileData, setProfileData] = useState({});
    const { t } = useTranslation();

    const url = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetch(`${url}/api/v1/profiles/getAllProfiles`);
                if (!response.ok) {
                    throw new Error('Error fetching profile data');
                }
                const data = await response.json();
                // Assuming the API returns an array and we want the first profile
                setProfileData(data[0]);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchProfileData();
    }, []);

    return (
        <div className={styles.footer}>
            <h3 className={styles.title}>{t("footer.title")}</h3>
            <div className={styles.iconContainer}>
                <a href={profileData.facebookLink} target="_blank" rel="noopener noreferrer" aria-label="Visit our Facebook page">
                    <FontAwesomeIcon className={styles.icon} icon={faFacebook} size="2x" />
                </a>
                <a href={`https://wa.me/2${profileData.whatsappNum}`} target="_blank" rel="noopener noreferrer" aria-label="Chat with us on WhatsApp">
                    <FontAwesomeIcon className={styles.icon} icon={faWhatsapp} />
                </a>
                <a href={profileData.instagramLink} target="_blank" rel="noopener noreferrer" aria-label="Visit our Instagram profile">
                    <FontAwesomeIcon className={styles.icon} icon={faInstagram} />
                </a>
                <a href={profileData.snapchatLink} target="_blank" rel="noopener noreferrer" aria-label="Visit our Snapchat account">
                    <FontAwesomeIcon className={styles.icon} icon={faSnapchatGhost} />
                </a>
                <a href={profileData.tiktokLink} target="_blank" rel="noopener noreferrer" aria-label="Visit our TikTok profile">
                    <FontAwesomeIcon className={styles.icon} icon={faTiktok} />
                </a>
                <a href={profileData.youtubeLink} target="_blank" rel="noopener noreferrer" aria-label="Visit our YouTube channel">
                    <FontAwesomeIcon className={styles.icon} icon={faYoutube} />
                </a>
            </div>

            <div className={styles.iconContainer2} dir='ltr'>
                <div className={styles.iconEmail}>
                    <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
                    <a href={`mailto:${profileData.email}`} target="_blank" rel="noopener noreferrer" aria-label={`Email us at ${profileData.email}`}>
                        <h3>{profileData.email}</h3>
                    </a>
                </div>
                <div className={styles.iconPhone}>
                    <FontAwesomeIcon className={styles.icon} icon={faPhone} />
                    <a href={`tel:${profileData.phone}`} aria-label={`Call us at ${profileData.phone}`}>
                        <h3>{profileData.phone}</h3>
                    </a>
                </div>

            </div>
            <div className={styles.servicesLink}>
                <div className={styles.firstOne}>
                    <img alt='دكتور اسنان' loading="eager" src={'/logo.jpg'} />
                    <h1>{t("footer.ourServices")}</h1>
                </div>
                <div className={styles.secondOne}>
                    <ServiceLinks />
                </div>
            </div>
            <div dir='ltr' className='text-center p-3 text-white'>
                Copyright to:&nbsp;
                <a
                    rel="noopener noreferrer"
                    className='text-white'
                    href='https://www.facebook.com/profile.php?id=100039366480977'
                    target="_blank"
                    aria-label="Visit Ahmed ELDeeb's Facebook profile"
                >
                    @Ahmed ELDeeb
                </a>
            </div>

        </div>
    );
};

export default Footer;
