// ExperienceCounter.jsx
import React, { useEffect, useState, useRef } from 'react';
import styles from './CustomersCounter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrophy, faBriefcase } from '@fortawesome/free-solid-svg-icons'; 
import { useTranslation } from 'react-i18next';
import CircularProgressBar from './CircularProgressBar';

const ExperienceCounter = () => {
    const [experienceCount, setExperienceCount] = useState(0);
    const [staffCount, setStaffCount] = useState(0);
    const [awardCount, setAwardCount] = useState(0);
    const experienceTarget = 35; 
    const staffTarget = 20;
    const awardTarget = 10000; 
    const ref = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    startCounting();
                } else {
                    // Reset the counts when not in view
                    resetCounts();
                }
            });
        }, { threshold: 0.1 }); // Trigger when 10% of the component is visible

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    const startCounting = () => {
        // Experience counter
        let experienceStart = 0;
        const experienceIncrement = Math.ceil(experienceTarget / 100);
        const experienceInterval = setInterval(() => {
            if (experienceStart < experienceTarget) {
                experienceStart += experienceIncrement;
                setExperienceCount(Math.min(experienceStart, experienceTarget));
            } else {
                clearInterval(experienceInterval);
            }
        }, 20);

        // Staff counter
        let staffStart = 0;
        const staffIncrement = Math.ceil(staffTarget / 1000);
        const staffInterval = setInterval(() => {
            if (staffStart < staffTarget) {
                staffStart += staffIncrement;
                setStaffCount(Math.min(staffStart, staffTarget));
            } else {
                clearInterval(staffInterval);
            }
        }, 20);

        // Award counter
        let awardStart = 0; 
        const awardIncrement = Math.ceil(awardTarget / 100);
        const awardInterval = setInterval(() => {
            if (awardStart < awardTarget) {
                awardStart += awardIncrement;
                setAwardCount(Math.min(awardStart, awardTarget));
            } else {
                clearInterval(awardInterval);
            }
        }, 20);
    };

    const resetCounts = () => {
        setExperienceCount(0);
        setStaffCount(0);
        setAwardCount(0);
    };

    return (
        <div className={styles.CustomerCounter} ref={ref}>
            <div>
                <CircularProgressBar 
                    value={experienceCount} 
                    target={experienceTarget} 
                    label={t('customerCounter.exp')} 
                    icon={<FontAwesomeIcon icon={faBriefcase} size="2x" />} 
                />
            </div>
            <div>
                <CircularProgressBar 
                    value={staffCount} 
                    target={staffTarget} 
                    label={t('customerCounter.stuff')} 
                    icon={<FontAwesomeIcon icon={faUser} size="2x" />} 
                />
            </div>
            <div>
                <CircularProgressBar 
                    value={awardCount} 
                    target={awardTarget} 
                    label={t('customerCounter.clients')} 
                    icon={<FontAwesomeIcon icon={faTrophy} size="2x" />} 
                />
            </div>
        </div>
    );
};

export default ExperienceCounter;
