import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import styles from './ServiceLinks.module.css'; // Import CSS module

const services = [
    { id: 1, key: 'prosthodontics', image: '/service/prosthodontics.webp' },
    { id: 2, key: 'oralSurgery', image: '/service/surgery.webp' },
    { id: 3, key: 'dentalImplants', image: '/service/dentalImplants.webp' },
    { id: 4, key: 'emergencyDentalCare', image: '/1.jpeg' },
    { id: 5, key: 'periodontics', image: '/service/Periodontics.webp' },
    { id: 6, key: 'endodontics', image: '/service/endodontics.webp' },
    { id: 7, key: 'generalDentistry', image: '/service/generalDentistry.webp' },
    { id: 8, key: 'pediatricDentistry', image: '/service/pediatricDentistry.webp' },
    { id: 9, key: 'orthodontics', image: '/service/orthodontics.webp' },
    { id: 10, key: 'cosmeticDentistry', image: '/service/cosmeticDentistry.webp' },
];

const ServiceLinks = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Handle the service click and navigate with state
    const handleServiceClick = (id) => {
        // Navigate to the service detail page, passing the services array in state
        navigate(`/services/${id}`, { state: { services } });
    };

    return (
        <div className={styles.linksContainer}>
            {services.map((service) => (
                <div
                    key={service.id}
                    onClick={() => handleServiceClick(service.id)} // Use onClick to navigate programmatically
                    className={styles.link}
                >
                    <span className={styles.decorativeDot}>•</span> 
                    {t(`services.${service.key}.title`)}
                </div>
            ))}
        </div>
    );
};

export default ServiceLinks;
