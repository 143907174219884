import React, { useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import styles from './ExplainTheService.module.css'; 
import { useTranslation } from 'react-i18next';

const ServiceDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // Retrieve the services array from the state passed by navigation
    const services = location.state?.services || [];
    const service = services.find((s) => s.id === parseInt(id)); // Find the service based on the ID

    // Scroll to the top of the page whenever the ID changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    // If the service is not found, show a "Service Not Found" message
    if (!service) return <div>{t('serviceNotFound')}</div>;

    return (
        <div className={styles.detailContainer}>
            {/* Display the service's image and details */}
            <img loading="eager" src={service.image} alt={t(`services.${service.key}.title`)} className={styles.detailImage} />
            <h2 className={styles.detailTitle}>{t(`services.${service.key}.title`)}</h2>
            <p className={styles.detailDescription}>{t(`services.${service.key}.description`)}</p>

            {/* Display a list of other services to click */}
            <div className={styles.cards}>
                {services.map((otherService) => (
                    <div
                        key={otherService.id}
                        className={styles.card}
                        onClick={() => navigate(`/services/${otherService.id}`, { state: { services } })}
                    >
                        <img 
                            loading="eager"  
                            src={otherService.image} 
                            alt={t(`services.${otherService.key}.title`)} 
                            className={styles.cardImage} 
                        />
                        <h4 className={styles.cardTitle}>{t(`services.${otherService.key}.title`)}</h4>
                        <p className={styles.cardDescription}>
                            {t(`services.${otherService.key}.description`).split(' ').slice(0, 20).join(' ')}...
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceDetail;
