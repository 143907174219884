import React from 'react';
import styles from './MedicalTourism.module.css';
import { useTranslation } from 'react-i18next';


const MedicalTourism = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.imgContainer}>
                <img loading="eager" src='/egypt.jpg' alt='دكتور اسنان'  />
            </div>
            <div className={styles.info}>
                <h1>{t("medicalTourism.title1")}</h1>
                <p>{t("medicalTourism.p1")}</p>
                <h1>{t("medicalTourism.title2")}</h1>
                <p>{t("medicalTourism.p2")}</p>
            </div>
            <div className={styles.youtubeVideo}>
                <iframe
                    src="https://www.youtube.com/embed/317e0qmAjVc?si=v6qwuaMfVRGDrJ3n"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="no-referrer"
                    allowFullScreen
                    className={styles.video}>
                </iframe>
            </div>

        </div>
    );
}

export default MedicalTourism;
