import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook
import styles from './BeforeAndAfterImages.module.css';
import ReactCompareImage from 'react-compare-image';

const BeforeAndAfterImages = () => {
    const navigate = useNavigate(); // Hook to navigate

    const images = [
        { left: '/beforeAndafter/05.jpg', right: '/beforeAndafter/055.jpg' },
        { left: "/beforeAndafter/02.jpg", right: "/beforeAndafter/022.jpg" },
        { left: "/beforeAndafter/03.jpg", right: "/beforeAndafter/033.jpg" },
        { left: "/beforeAndafter/04.jpg", right: "/beforeAndafter/044.jpg" },
        { left: '/beforeAndafter/06.jpg', right: '/beforeAndafter/066.jpg' },
        { left: '/beforeAndafter/07.jpg', right: '/beforeAndafter/077.jpg' }
    ];


    const handleShowAllClick = () => {
        // Navigate to the new page showing all images
        navigate('/all-images');
    };

    return (
        <div className={styles.homeContainer}>
            <div className={styles.container}>
                <ReactCompareImage className={styles.image} leftImage={images[0].left} rightImage={images[0].right} />
            </div>
            <div className={styles.container}>
                <ReactCompareImage className={styles.image} leftImage={images[1].left} rightImage={images[1].right} />
            </div>

            {/* Button to go to the page with all images */}
            <button className={styles.showMoreButton} onClick={handleShowAllClick}>
                Show More
            </button>
        </div>
    );
};

export default BeforeAndAfterImages;
