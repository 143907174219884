import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Home from '../Home/Home';
import OurService from '../OurService/OurService';
import AboutInfo from '../About/AboutInfo';
import User from '../User/User';
import Location from '../Location/Location';
import BeforeAndAfter from '../BeforeAndAfter/BeforeAndAfter';
import Profiles from '../Profiles/Profiles';
import CustomerCounter from '../CounterCustomers/CustomerCounter';
import BeforeAndAfterVideos from '../BeforeAndAfterVideos/BeforeAndAfterVideos';
import ExplainTheService from '../ExplainTheService/ExplainTheService';
import HomeDetails from '../Home/HomeDetails';
import BeforeAndAfterImages from '../BeforeAndAfterImages/BeforeAndAfterImages';

const services = [
  { id: 1, key: 'prosthodontics', image: '/service/prosthodontics.webp' },
  { id: 2, key: 'oralSurgery', image: '/service/surgery.webp' },
  { id: 3, key: 'dentalImplants', image: '/service/dentalImplants.webp' },
  { id: 4, key: 'emergencyDentalCare', image: '/1.jpeg' },
  { id: 5, key: 'periodontics', image: '/service/Periodontics.webp' },
  { id: 6, key: 'endodontics', image: '/service/endodontics.webp' },
  { id: 7, key: 'generalDentistry', image: '/service/generalDentistry.webp' },
  { id: 8, key: 'pediatricDentistry', image: '/service/pediatricDentistry.webp' },
  { id: 9, key: 'orthodontics', image: '/service/orthodontics.webp' },
  { id: 10, key: 'cosmeticDentistry', image: '/service/cosmeticDentistry.webp' },
];

const MainHome = () => {
  const location = useLocation();

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1)); // Remove the '#' from the hash
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location]);

  return (
    <div>
      <Home id="Home" />
      <BeforeAndAfterImages id="BeforeAndAfterImages"/>
      {/* <HomeDetails/> */}
      <ExplainTheService id ="OurService" services={services}/>
      {/* <OurService id="OurService" /> */}
      <AboutInfo id="AboutInfo" />
      <Profiles id="Profiles"/>
      <CustomerCounter id="CustomerCounter"/>
      <BeforeAndAfter id="BeforeAndAfter" />
      <BeforeAndAfterVideos id="BeforeAndAfterVideos"/>
      <User id="User" />
      <Location id="ContactUs" />
    </div>
  );
};

export default MainHome;
