import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './User.module.css';
import ConfirmationModal from './ConfirmationModal';
import { useTranslation } from 'react-i18next';

const services = [
    { ar: "ابتسامة هوليود", en: "Hollywood Smile" },
    { ar: "تبييض الأسنان", en: "Teeth whitening" },
    { ar: "زراعة الأسنان", en: "Dental implants" },
    { ar: "الحشوات التجميلية", en: "Cosmetic fillings" },
    { ar: "تقويم الأسنان", en: "Orthodontics" },
    { ar: "أسنان الأطفال", en: "Children's teeth" },
    { ar: "التيجان والجسور", en: "Crowns and bridges" },
    { ar: "علاج اللثة وجذور الأسنان", en: "Gum and root canal treatment" },
];

const User = () => {
    const [serviceOptions, setServiceOptions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const url = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const titles = services.map(service => i18n.language === 'ar' ? service.ar : service.en);
        setServiceOptions(titles);
    }, [i18n.language]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        email: Yup.string().email('Invalid email format.'),
        phone: Yup.string().required('Phone is required.'),
        typeOfService: Yup.string().required('Service is required.'),
        message: Yup.string().nullable(),
    });

    const handleSubmit = async (values, { resetForm }) => {
        if (!values.email && !values.phone) {
            alert('Either email or phone is required.');
            return;
        }

        // Convert Arabic service to English if needed
        const selectedService = values.typeOfService;
        const selectedServiceObj = services.find(service => 
            i18n.language === 'ar' ? service.ar === selectedService : service.en === selectedService
        );

        // Update the service type in values to English
        if (i18n.language === 'ar' && selectedServiceObj) {
            values.typeOfService = selectedServiceObj.en;
        }

        // console.log('Form Data:', values);

        try {
            const response = await fetch(`${url}/api/v1/users/saveUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(values),
            });

            const data = await response.text();
            // console.log('Response Status:', response.status);
            // console.log('Response Data:', data);

            if (response.ok) {
                setModalOpen(true); // Open the modal
            } else {
                const errorMessage = data?.message || 'Failed to save Information';
                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error('Error saving Information:', error);
            alert(`Failed to save Information: ${error.message}`);
        }
        resetForm();
    };

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                phone: '',
                typeOfService: '',
                message: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form id="User" className={styles.fromContainer}>
                    <div className={styles.title}>
                        <h2>{t('user.title')}</h2>
                    </div>
                    <div className={styles.one}>
                        <div className={styles.name}>
                            <label htmlFor="name">{t('user.name')}:</label>
                            <Field className={styles.field} type="text" id="name" name="name" />
                            <ErrorMessage name="name" component="div" className={styles.error} />
                        </div>

                        <div className={styles.phone}>
                            <label htmlFor="phone">{t('user.phone')}:</label>
                            <Field className={styles.field} type="tel" id="phone" name="phone" />
                            <ErrorMessage name="phone" component="div" className={styles.error} />
                        </div>
                    </div>

                    <div className={styles.two}>
                        <div className={styles.email}>
                            <label htmlFor="email">{t('user.email')}:</label>
                            <Field className={styles.field} type="email" id="email" name="email" />
                        </div>

                        <div className={styles.service}>
                            <label htmlFor="typeOfService">{t('user.typeOfService')}:</label>
                            <Field className={styles.field} as="select" id="typeOfService" name="typeOfService">
                                <option value="">{t('user.chooseTheService')}</option>
                                {serviceOptions.map((title, index) => (
                                    <option  key={index} value={title}>{title}</option>
                                ))}
                            </Field>
                            <ErrorMessage name="typeOfService" component="div" className={styles.error} />
                        </div>
                    </div>

                    <div className={styles.three}>
                        <div className={styles.message}>
                            <label htmlFor="message">{t('user.message')}:</label>
                            <Field className={styles.field} as="textarea" id="message" name="message" />
                        </div>
                    </div>

                    <button className={styles.btn} type="submit">{t('user.btnSubmit')}</button>

                    {/* Render the modal here */}
                    <ConfirmationModal 
                        isOpen={isModalOpen} 
                        onClose={() => setModalOpen(false)} 
                    />
                </Form>
            )}
        </Formik>
    );
};

export default User;
