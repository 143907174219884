import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import styles from './BeforeAndAfterVideos.module.css';
import { Mousewheel, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

const BeforeAndAfterVideos = () => {
    const [videos, setVideos] = useState([]);
    const videoRefs = useRef([]); // Ref to store video elements
    const { t } = useTranslation();
    const url = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(`${url}/api/v1/videos/getAllVideos`);
                // const response = await fetch(`${url}/api/v1/videos/videos`);
                if (!response.ok) throw new Error("Network response was not ok");
                const data = await response.json();
                setVideos(data);
            } catch (error) {
                console.error("Failed to fetch videos:", error);
            }
        };
        fetchVideos();
    }, []);

    const handleSlideChange = (swiper) => {
        // Stop all videos
        videoRefs.current.forEach(video => {
            if (video) {
                video.pause();
            }
        });
    
        // Play the current video if it is clicked
        const currentVideo = videoRefs.current[swiper.activeIndex];
        if (currentVideo) {
            currentVideo.play().catch(error => {
                console.error("Failed to play video:", error);
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.one}>
                <h1 style={{fontSize: '30px'}}>{t("showOurWork")}</h1>
            </div>
            <div className={styles.two}>
                {videos.length > 0 ? (
                    <Swiper
                        direction={'vertical'}
                        slidesPerView={1}
                        spaceBetween={30}
                        mousewheel={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Mousewheel, Pagination]}
                        onSlideChange={handleSlideChange}
                        className={styles.swiper}
                    >
                        {videos.map((video, index) => (
                            <SwiperSlide key={video.id} className={styles.swiperSlide}>
                                <video 
                                    ref={el => videoRefs.current[index] = el}
                                    className={styles.video} 
                                    controls
                                >
                                    <source src={`${video.name}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className={styles.noVideosMessage}>
                        <p>{t("noVideosAvailable")}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BeforeAndAfterVideos;
