import React, { Component } from 'react';
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/Login/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainHome from './Components/MainHome/MainHome';
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import Dashboard from './Components/Dashboard/Dashboard';
import ProtectedRoute from './Utilty/ProtectedRoute';
import MedicalTourism from './Components/MedicalTourism/MedicalTourism';
import ServiceDetail from './Components/ExplainTheService/ServiceDetail';
import ContactIcons from './Components/ContactIcons/ContactIcons';
import NotFound from './Components/NotFound/NotFound';
import AboutInfo from './Components/About/AboutInfo';
import ExplainTheService from './Components/ExplainTheService/ExplainTheService';
import AllImagesPage from './Components/BeforeAndAfterImages/AllImagesPage';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // Start with loading state as true
    };
  }

  componentDidMount() {
    // Set a timeout to hide the loading screen after 2 seconds
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000); // 2000ms = 2 seconds
  }

  render() {
    const { loading } = this.state;

    // If the app is still loading, show the loading screen
    if (loading) {
      return (
        <div className="loading-screen">
          {/* Loading spinner or animation */}
          <div className="spinner"></div>
        </div>
      );
    }

    // Once loading is done, render the rest of the application
    return (
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<MainHome />} />
            <Route path="/all-images" element={<AllImagesPage/>} />
            <Route path='/medicalTourism' element={<MedicalTourism />} />
            <Route path='/about' element={
              <div style={{ marginTop: '70px' }}>
                <AboutInfo />
              </div>
            } />
            <Route path="/services/:id" element={<ServiceDetail />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer />
          <ContactIcons />
        </div>
      </Router>
    );
  }
}

export default App;
