import React from 'react';
import styles from './Location.module.css'
import { useTranslation } from 'react-i18next';

const Location = () => {
    const { t } = useTranslation();

    return (
        <div id="ContactUs" className={styles.locationContainer}>

            <div className={styles.title}>
                <h1 style={{fontSize: '30px'}}>{t('location.title')}</h1>
                <h4>{t('location.address')}</h4>
            </div>
            <div className={styles.location}>
                <iframe
                    title='location'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2385.0091445272265!2d31.192033285071016!3d30.057404242936393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145841195e2a244f%3A0xa875ea743d2a8dc9!2sDoctor%20Tharwat%20Dental%20Clinic!5e1!3m2!1sen!2sus!4v1728203569739!5m2!1sen!2sus"
                    width="600"
                    height="450"
                    style={{ border: 0 }}  // Change this line
                    allowFullScreen=""
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>

        </div>
    );
}

export default Location;
