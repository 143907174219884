import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import styles from './Profiles.module.css';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import axios from 'axios';

const Profiles = () => {
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const url = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchDoctors = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${url}/api/v1/doctors/getAllDoctors`);
                setDoctors(response.data);
            } catch (error) {
                console.error('Error fetching doctors:', error);
                setError('Failed to load doctors. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchDoctors();
    }, [url]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (doctors.length === 0) {
        return <p>No doctors available.</p>;
    }

    return (
        <div className={styles.container}>
            <div>
                <h1 style={{ fontSize: '30px' }}>{t('profile.title')}</h1>
                <p style={{ color: '#fff' }}>{t('profile.text')}</p>
            </div>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 60,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className={styles.swiper}
            >
                {doctors.map((doctor, index) => (
                    <SwiperSlide key={doctor.id} className={styles.swiperSlide}>
                        <img loading="eager" src={doctor.image} alt={`Doctor ${index + 1}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Profiles;
