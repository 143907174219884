import React from 'react'

const NotFound = () => {
  return (
    <div className='' style={{width: '100%', height: '100vh', display: 'flex', justifyContent: "center", alignItems: "center"}}>
        <h1>404 Not Found</h1>
    </div>
  )
}

export default NotFound