import React, { useState, useEffect, useRef } from 'react';
import styles from './BeforeAndAfter.module.css';
import { useTranslation } from 'react-i18next';

const BeforeAndAfter = () => {
    const [images, setImages] = useState([]);
    const carouselRef = useRef(null);
    const { t } = useTranslation();
    const url = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch(`${url}/api/v1/images/getAllImages`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // console.log(data);
                setImages(data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    // const handleScroll = (direction) => {
    //     const { scrollLeft, clientWidth } = carouselRef.current;
    //     carouselRef.current.scrollTo({
    //         left: direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth,
    //         behavior: 'smooth',
    //     });
    // };

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h1 style={{fontSize: '30px'}}>{t('beforeAndAfter')}</h1>
            </div>

            {images.length === 0 ? (
                <div className={styles.noImagesMessage}>
                    <p>{t('noImagesAvailable')}</p>
                </div>
            ) : (
                <div className={styles.carousel}>
                    {/* <button className={styles.scrollButton} onClick={() => handleScroll('left')}>&lt;</button> */}
                    <div className={styles.carouselWrapper} ref={carouselRef}>
                        {images.map(image => (
                            <div className={styles.card} key={image.id}>
                                <img  loading="eager"
                                    src={image.image}
                                    alt={`Image ${image.id}`}
                                    className={styles.image}
                                />
                            </div>
                        ))}
                    </div>
                    {/* <button className={styles.scrollButton} onClick={() => handleScroll('right')}>&gt;</button> */}
                </div>
            )}
        </div>
    );
};

export default BeforeAndAfter;
