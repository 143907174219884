import React, { useState, useEffect } from 'react';

const CountDownTimer = ({ keyReset, onTimerComplete }) => {
    const initialTime = 60; // 60 seconds
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        setTimeLeft(initialTime); // Reset time when keyReset changes
        setIsRunning(true); // Start the timer
    }, [keyReset]);

    useEffect(() => {
        if (timeLeft <= 0) {
            setIsRunning(false);
            if (onTimerComplete) onTimerComplete(); // Notify parent when timer completes
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer); // Stop the timer when it reaches 0
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000); // Update every second

        // Cleanup the timer on component unmount
        return () => clearInterval(timer);
    }, [timeLeft, onTimerComplete]);

    return (
        <div>
            {timeLeft}
        </div>
    );
};

export default CountDownTimer;
