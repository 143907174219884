import React from 'react';
import styles from './User.module.css';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    if (!isOpen) return null; // Do not render if not open

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h1><span><img alt='دكتور اسنان'  loading="eager" src={'/logo.jpg'} width="60" height="60" style={{ borderRadius: '50%', margin: '10px' }} /></span>{t("confirmModel.title")}</h1>

                <p>{t("confirmModel.message")}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ConfirmationModal;
