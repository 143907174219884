import React from 'react'
import styles from './About.module.css'
import { useTranslation } from 'react-i18next';

const AboutInfo = () => {
    const { t } = useTranslation();

    return (
        <div dir='ltr' id="AboutInfo" className={`${styles.aboutContainer}`} >
            <div className={styles.pargaph}>
                <h1>{t('about.title2')}</h1>
                <p>{t('about.ourGoals')}</p>
                <img alt='دكتور اسنان' loading="eager" src="./22.webp" />
                <h1>{t('about.title3')}</h1>
                <p>{t('about.ourVision')}</p>
                <img alt='دكتور اسنان' loading="eager" src="./222.webp" />
            </div>
            <div className={styles.container}>
                <div className={styles.one}>
                    <img alt='دكتور اسنان'  loading="eager"  src={'/logo.jpg'} width="150" height="150"style={{ cursor: 'pointer' }} />
                    <h1>{t('about.title')}</h1>
                    <p>
                        {t('about.text')}
                    </p>
                </div>
                <div dir='ltr'  className={styles.two} >
                    <img alt='دكتور اسنان'  loading="eager" src='2.JPG'></img>
                </div>
            </div>

        </div>
    )
}

export default AboutInfo