import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ExplainTheService.module.css';
import { useTranslation } from 'react-i18next';

const ExplainTheService = ({ services }) => {
    const navigate = useNavigate();
    const [visibleCount] = useState(2);
    const { t } = useTranslation();

    
    const handleServiceClick = (id) => {
        navigate(`/services/${id}`, { state: { services } });
        console.log(`/services/${id}`);
    };

    return (
        <div id="OurService" className={styles.serviceCards}>
            {/* <h3 className={styles.text}>{t('home.text')}</h3> */}
            <h1 style={{ margin: '30px' }}>{t("services.title")}</h1>
            <div className={styles.cards}>
                {services.slice(0, visibleCount).map((service) => {
                    return (
                        <div
                            className={styles.card}
                            key={service.id}
                            onClick={() => handleServiceClick(service.id)} // Trigger navigation with service ID
                        >
                            <img
                                loading="eager"
                                src={service.image}
                                alt={t(`services.${service.key}.title`)}
                                className={styles.cardImage}
                            />
                            <h3 className={styles.cardTitle}>{t(`services.${service.key}.title`)}</h3>
                            <p className={styles.cardDescription}>
                                {`${t(`services.${service.key}.description`).split(' ').slice(0, 20).join(' ')}...`}
                            </p>
                        </div>
                    );
                })}
            </div>
            <div className={styles.btn}>
                {visibleCount < services.length && (
                    <button className={styles.showMoreButtonService} onClick={() => handleServiceClick(1)} >
                        {t('services.showMoreServices')} {/* Optional: Add translation for button text */}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ExplainTheService;
