// CircularProgressBar.jsx
import React from 'react';
import styles from './CircularProgressBar.module.css';

const CircularProgressBar = ({ value, target, label, icon }) => {
    const radius = 50; // radius of the circle
    const strokeWidth = 10; // stroke width
    const normalizedRadius = radius - strokeWidth * 0.5; // adjusted radius
    const circumference = normalizedRadius * 2 * Math.PI; // circumference of the circle
    const progress = (value / target) * circumference; // calculate the progress

    return (
        <div className={styles.progressContainer}>
            <div className={styles.iconContainer}>
                {icon}
            </div>
            <svg height={radius * 2} width={radius * 2}>
                <circle
                    stroke="#e6e6e6" // Background circle color
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    stroke="#C6A664" // Progress circle color
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    strokeDasharray={circumference + ' ' + circumference}
                    strokeDashoffset={circumference - progress}
                    style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
                />
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className={styles.value}>
                    {value.toLocaleString()}+
                </text>
            </svg>
            <h2 className={styles.label}>{label}</h2>
        </div>
    );
};

export default CircularProgressBar;
