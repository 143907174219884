import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from './Login.module.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');
  const url = process.env.REACT_APP_BASE_URL;

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "At least 8 chars in password"),
  });

  // Check if the user is already logged in
  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    }
  }, [token, navigate]);

  const handleSubmit = async (values) => {
    setErrorMessage(""); // Reset error message before each submission
    try {
      const response = await fetch(`${url}/api/v1/auth/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
        }),
      });

      if (!response.ok) {
        setErrorMessage("Username or password not correct."); // Set error message
        throw new Error('Login failed! Please check your credentials.');
      }

      const newToken = await response.text();
      localStorage.setItem('authToken', newToken);
      navigate('/dashboard'); // Navigate to dashboard

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{ email: "", password: "", rememberMe: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.loginForm}>
            <div className={styles.headingContainer}>
              <h2 className={styles.heading}>Login Page</h2>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>Username or Email</label>
              <Field type="email" id="email" name="email" className={styles.inputField} />
              <ErrorMessage name="email" component="div" className={styles.errorMessage} />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.label}>Enter Your Password</label>
              <Field type="password" id="password" name="password" className={styles.inputField} />
              <ErrorMessage name="password" component="div" className={styles.errorMessage} />
            </div>

            {/* Display error message */}
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

            <button type="submit" className={styles.submitButton} disabled={isSubmitting}>
              Submit
            </button>
            <div className={`${styles.formGroup} ${styles.formGroupFlex}`}>
              <div>
                <Field type="checkbox" id="rememberMe" name="rememberMe" className={styles.checkbox} />
                <label htmlFor="rememberMe" className={styles.checkboxLabel}>Remember Me</label>
              </div>
              <a rel="noopener noreferrer" href="/forgetpassword" className={styles.link}>Forgot Your Password?</a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;
