import React, { useEffect, useState } from 'react';
import styles from './Home.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [imageIndex, setImageIndex] = useState(0); 
    const imageSources = [
        '4.jpeg', 
        '222.webp', 
    ];


    useEffect(() => {
        // Cycle through images every 1 second
        const intervalId = setInterval(() => {
            setImageIndex(prevIndex => (prevIndex + 1) % imageSources.length);
        }, 2000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);


    // Navigation handler
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div id="Home" className={styles.home} dir='ltr'>
            <div className={styles.containerOne}>
                <img 
                    alt='دكتور اسنان' 
                    loading="eager" 
                    src={imageSources[imageIndex]} 
                />
            </div>
            <div className={styles.containerTwo}>
                <div className={`${styles.one}`}>
                    <h2 className={styles.dr}>{t('home.title')}</h2>
                    <h3>{t('home.text')}</h3>
                </div>
                <div className={styles.three}>
                    <h2 style={{ color: '#fff', cursor: 'pointer' }} onClick={() => handleNavigation('/#ContactUs')}>
                        {t('home.bookNow')}
                    </h2>
                </div>
            </div>
        </div>
    );
}

export default Home;
